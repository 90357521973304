<template>
  <div class="pt-2">
    <div class="p-1 text-left">
      <button
        class="btn mx-1"
        v-for="group in data"
        v-if="group.isAvailable"
        :key="group.groupId"
        :class="[{'btn-primary':selectGroup.groupId===group.groupId}, {'btn-light text-primary':selectGroup.groupId!==group.groupId}]"
        @click="selectGroup=group"
      >{{ groupName(group.groupId) }}</button>
    </div>
    <AccountSettingRateTable v-if="selectGroup.groupId" :data="selectGroup" :ref-setting="refSetting[selectGroup.groupId]" />
    <div v-if="!isHasOpened">ไม่มีกลุ่มเปิด</div>
  </div>
</template>
<script>
import AccountSettingRateTable from './AccountSettingRateTable'
export default {
  name: 'NewAccountSettingRate',
  components: {
    AccountSettingRateTable
  },
  props: ['data', 'refSetting'],
  data() {
    return {
      selectGroup: {
        groupId: null
      }
    }
  },
  computed: {
    isHasOpened() {
      if(!this.data)
        return false

      const openGroups = []
      for(const [key, group] of Object.entries(this.data)) {
        if(group.isAvailable) {
          openGroups.push(group)
        }
      }

      return openGroups.length > 0
    }
  },
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    }
  },
  mounted() {
    for (const groupId in this.data) {
      if(this.data[groupId].isAvailable) {
        this.selectGroup = this.data[groupId]
        break;
      }
    }
  }
}
</script>
